.main {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  height: 700px;
  position: relative;
  overflow: hidden;
  background: #0b0e28;
  display: flex;
  justify-content: center;
  @media (max-width: 1200px) {
    height: 550px;
  }
  @media (max-width: 880px) {
    flex-direction: column;
    height: 1050px;
    padding-top: 60px;
    justify-content: flex-start;
  }
  @media (max-width: 600px) {
    height: 800px;
  }
  @media (max-width: 450px) {
    height: 700px;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    height: 700px;
    position: relative;
    overflow: hidden;
    max-width: 1440px;
    @media (max-width: 1200px) {
      height: 550px;
    }
    @media (max-width: 880px) {
      flex-direction: column;
      height: 1050px;
      padding-top: 60px;
      justify-content: flex-start;
    }
    @media (max-width: 600px) {
      height: 800px;
    }
    @media (max-width: 450px) {
      height: 700px;
    }
  }
  .left {
    margin-top: 120px;
    margin-left: 60px;
    @media (max-width: 1100px) {
      margin-left: 40px;
    }
    @media (max-width: 880px) {
      margin-top: 0px;
      margin-left: 30px;
    }
    @media (max-width: 450px) {
      margin-top: 0px;
      margin-left: 20px;
    }
    .block{
      position: relative;
      .icon {
        position: absolute;
      
        right: 80px;
        @media (max-width: 450px) {
          width: 250px;
          right: 55px;
          bottom: -17px;
        }
        @media (max-width: 350px) {
          width: 200px;
          right: 5px;
          bottom: -17px;
        }
        // bottom: 0;
      }
      .title {
        font-family: 'Circle Thin Font', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 84.7181px;
        line-height: 88.9%;
        color: white;
        max-width: 1000px;
        span {
          background: linear-gradient(93.26deg, #739fff 2.19%, #4b2eff 55.81%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
        @media (max-width: 1200px) {
          font-size: 60px;
          line-height: 53.34px;
        }
        @media (max-width: 880px) {
          font-size: 84.7181px;
          line-height: 88.9%;
        }
        @media (max-width: 600px) {
          font-size: 60px;
          line-height: 53.34px;
        }
        @media (max-width: 450px) {
          font-size: 44px;
          line-height: 39.12px;
        }
      }
    }
    
    .formTop {
      position: absolute;
      top: 0;
      left: 0;
      // z-index: -1;
    }
    
    .text {
      margin-top: 40px;
      font-family: 'Gilroy-Regular', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      max-width: 400px;
    }
    .btns {
      margin-top: 21px;
      display: flex;
      gap: 15px;
      @media (max-width: 360px) {
        img {
          width: 130px;
        }
      }
    }
  }
  .right {
    position: relative;
    @media (max-width: 880px) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 26px;
    }
    .figure {
      position: absolute;
      z-index: 10;
      width: 444.27px;
      height: 752.41px;
      top: 396px;
      left: -40px;
      background: rgba(255, 255, 255, 0.05);
      backdrop-filter: blur(20px);
      border-radius: 30px;
      transform: rotate(129.48deg);
      @media (max-width: 1200px) {
        top: 300px;
      }
      @media (max-width: 880px) {
        top: 396px;
      }
      @media (max-width: 600px) {
        top: 270px;
      }
      @media (max-width: 450px) {
        top: 200px;
      }
    }
    .circle {
      position: relative;
      width: 585px;
      height: 585px;
      border-radius: 50%;
      background: #3675fe;
      img {
        position: absolute;
        left: -80px;
        z-index: 11;
        top: -50px;
        width: 120%;
      }
      @media (max-width: 1200px) {
        width: 416px;
        height: 416px;
        img {
          width: 120%;
          left: -70px;
          z-index: 11;
          top: -23px;
        }
      }
      @media (max-width: 880px) {
        width: 625px;
        height: 625px;
        img {
          width: auto;
          left: -110px;
          z-index: 11;
          top: -30px;
        }
      }
      @media (max-width: 600px) {
        width: 416px;
        height: 416px;
        img {
          width: 120%;
          left: -70px;
        }
      }
      @media (max-width: 450px) {
        width: 314px;
        height: 314px;
        img {
          width: 120%;
          left: -30px;
        }
      }
    }
  }
}
