footer {
  height: 80px;
  display: flex;
  justify-content: center;
  background: #4882fe;
  width: 100%;
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 60px;
    max-width: 1440px;
    width: 100%;
    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      gap: 30px;
      padding: 15px 30px;
    }
    @media (max-width: 450px) {
      padding: 15px 20px;
    }
  }
  .title {
    font-family: 'Gilroy-Regullar', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #ffffff;
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 19px;
      order: 2;
    }
    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
  .textBLock {
    display: flex;
    gap: 40px;
    align-items: center;
    @media (max-width: 768px) {
      justify-content: space-between;
      width: 100%;
    }
    .text {
      font-family: 'Gilroy-Regular', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      text-transform: capitalize;
      color: #ffffff;
      @media (max-width: 400px) {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}
