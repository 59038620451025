.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
 
  .btn {
    background: #3675fe;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    font-family: 'Gilroy-Regular', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    padding: 16px 0;
    width: 411px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    img {
      animation: 1s linear 0s normal none infinite running spin;
    }
    &:hover{
      transform: scale(1.05);
      box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.15);
    }
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}