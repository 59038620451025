.services {
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;

  .wrapper {
    max-width: 1440px;
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 100px;
    padding: 120px 60px;
    @media (max-width: 880px) {
      padding: 70px 30px;
      gap: 65px;
    }
    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      gap: 65px;
    }
  }
  .left {
    .title {
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 73px;
      color: #222232;
     
      @media (max-width: 880px) {
        font-size: 34px;
        line-height: 41px;
      }
      @media (max-width: 400px) {
        font-size: 44px;
        line-height: 53px;
      }
    }
    .text {
      margin-top: 20px;
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #797892;

      &:nth-child(3) {
        margin-top: 14px;
        @media (max-width: 880px) {
          margin-top: 8px;
        }
        @media (max-width: 600px) {
          margin-top: 10px;
        }
      }
      @media (max-width: 880px) {
        line-height: 14px;
        font-size: 12px;
        margin-top: 14px;
      }
      @media (max-width: 600px) {
        font-size: 18px;
        line-height: 19px;
        margin-top: 18px;
      }
    }
  }
  .right {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
    @media (max-width: 880px) {
      gap: 37px;
    }
    @media (max-width: 600px) {
      gap: 20px;
    }
    @media (max-width: 400px) {
      grid-template-columns: 1fr;
      gap: 40px;
    }
    .block {
      position: relative;
      .container {
        width: 70px;
        height: 70px;
        background: #3675fe;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 880px) {
          width: 40px;
          height: 40px;
          img {
            width: 18px;
            height: 18px;
          }
        }
        @media (max-width: 400px) {
          width: 70px;
          height: 70px;
          img {
            width: auto;
            height: auto;
          }
        }
      }
      .text {
        margin-top: 25px;
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #0b0e28;
        position: relative;
        svg {
          position: absolute;
          cursor: pointer;
          transition: 0.3s ease;
          margin-left: 4px;
          @media (max-width: 880px) {
            width: 20px;
            height: 20px;
          }
          &:hover ~ .openText {
            display: block;
            path {
              fill: black;
            }
          }
        }
        @media (max-width: 880px) {
          font-size: 12px;
          line-height: 15px;
          margin-top: 12px;
        }
        @media (max-width: 400px) {
          max-width: 252px;
          font-size: 20px;
          line-height: 24px;
        }
        .openText {
          display: none;
          position: absolute;
          top: 30px;
          left: -180px;
          z-index: 10;
          width: 282px;
          padding: 20px;
          background: #ffffff;
          border-radius: 5px;
          font-family: 'Gilroy', sans-serif;
          font-style: normal;
          filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.25));
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: #797892;
          &:hover {
            display: block;
          }
          @media (max-width: 800px) {
            width: 200px;
          }
          @media (max-width: 600px) {
            width: 180px;
            padding: 16px;
            left: -25px;
          }
        }
      }
    }
  }
}
