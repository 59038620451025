.header {
  width: 100%;
  height: 70px;
  position: relative;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  background: #0b0e28;
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 60px;
    height: 100%;
    max-width: 1440px;
    width: 100%;
    @media (max-width: 850px) {
      padding: 0 30px;
    }
    @media (max-width: 450px) {
      padding: 0 20px;
    }
    .burgerMenu {
      display: none;
      cursor: pointer;
      @media (max-width: 900px) {
        display: block;
      }
    }
    .logo {
      display: flex;
      gap: 8px;
      align-items: center;
      div {
        font-family: 'Hiragino Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 19.7594px;
        line-height: 98.5%;
        color: #ffffff;
      }
    }
    .routesContainer {
      .footer {
        display: none;
      }
      .headerMenu {
        display: none;
      }
      .routes {
        display: flex;
        align-items: center;
        gap: 40px;
      }
      .route {
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
        cursor: pointer;
        position: relative;
        &:hover{
          &::before {
            content: ' ';
            // display: none;
            position: absolute;
            top: 22px;
            left: 40%;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: #3675FE;
          }
        }
      }
      .drop {
        position: relative;

        .initialValue {
          display: flex;
          align-items: center;
          cursor: pointer;
          gap: 2px;
          .text {
            font-family: 'Gilroy-Bold', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #ffffff;
            text-transform: uppercase;
          }
          img {
            margin-right: 5px;
          }
          .icon {
            transition: 0.3s all;
            transform: rotate(180deg);
            &.active {
              transform: rotate(0deg);
            }
          }
        }
        .list {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          background: #ffffff;
          flex-direction: column;
          padding: 20px;
          position: absolute;
          top: 30px;
          border-radius: 5px;
          .value {
            font-family: 'Gilroy-Bold', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #797892;
            text-transform: uppercase;
            cursor: pointer;
            padding: 12px 0;
            &.active {
              color: #222232;
            }
          }
        }
      }
      &.active {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1000;
        box-sizing: border-box;
        background: #0b0e28;
        padding: 40px 20px;
        .headerMenu {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
            .cross {
              cursor: pointer;
            }
          .logo {
            display: flex;
            align-items: center;
            color: white;
          }
        }
        .routes {
          flex-direction: column;
        }
        .footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 40px;
          @media (max-width: 900px) {
            margin: 0;
          }
          .text {
            font-family: 'Gilroy-Regular', sans-serif;
            font-size: 18px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0em;
            text-transform: capitalize;
            color: #ffffff;
          }
        }
      }
      @media (max-width: 900px) {
        display: none;
      }
    }
  }
}
