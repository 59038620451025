.plansSection {
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  .wrapper {
    padding: 120px 60px;
    max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
    @media (max-width: 1024px) {
      padding: 80px 42px;
    }
    @media (max-width: 880px) {
      padding: 80px 30px;
    }
  }
  .marginBtn {
    margin: 50px auto 0;
  }
  .title {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 73px;
    color: #222232;
    width: 100%;
    text-align: center;
  }
  .plans {
    margin-top: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 60px;
    @media (max-width: 1024px) {
      gap: 12px;
    }
    @media (max-width: 600px) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    .blockPlan {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px solid #dfdfe6;
      border-radius: 10px;
      padding: 40px;
      max-width: 411px;
      @media (max-width: 600px) {
        width: 100%;
      }
      .icons {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      @media (max-width: 880px) {
        padding: 24px;
      }
      .status {
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        color: #222232;
        text-align: center;
        margin-top: 24px;
        @media (max-width: 880px) {
          font-size: 25.995px;
          line-height: 32px;
        }
      }
      .text {
        margin-top: 11px;
        font-family: 'Gilroy-Regular', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #222232;
        @media (max-width: 1024px) {
          font-size: 11px;
          line-height: 13px;
        }
      }
      .cost {
        font-family: 'Gilroy-Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 45.2941px;
        line-height: 55px;
        text-align: center;
        color: #222232;
        margin: 60px 0;
        @media (max-width: 1024px) {
          margin: 40px 0;
        }
        @media (max-width: 880px) {
          margin: 47px 0;
          font-size: 30px;
          line-height: 36px;
          letter-spacing: 0em;
        }
      }
      .goals {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
        .goal {
          font-family: 'Gilroy-Regular', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #222232;
          display: flex;
          align-items: center;
          gap: 15px;
          @media (max-width: 880px) {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
      &:nth-child(2) {
        background: #171a33;
        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.25);
        border: none;
        .status {
          color: #ffc500;
        }
        .text,
        .cost,
        .goal {
          color: #ffffff;
        }
      }
    }
  }
}
