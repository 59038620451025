body {
  font-family: '-apple-system', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue',
    Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: #0b0e28;
  margin: 0 auto;
}
.test {
  font-family: 'Hiragino Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 19.7594px;
  line-height: 98.5%;
  // color: #ffffff;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

*::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #585858;
  border-radius: 10px;
}

*::-webkit-scrollbar-corner {
  background: transparent;
}
