.section {
  background: #0b0e28;
  display: flex;
  justify-content: center;
  width: 100%;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 140px 60px;
    max-width: 1440px;
    box-sizing: border-box;
    @media (max-width: 1024px) {
      padding: 80px 40px;
    }
    @media (max-width: 880px) {
      padding: 60px 30px;
    }
    @media (max-width: 600px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
    }
    @media (max-width: 375px) {
      padding: 50px 20px;
    }
  }
  .title {
    font-family: 'Gilroy-Regular', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 73px;
    color: #ffffff;
    @media (max-width: 1024px) {
      font-size: 44px;
      line-height: 53px;
    }
    @media (max-width: 880px) {
      font-size: 34px;
      line-height: 41px;
    }
    @media (max-width: 375px) {
      font-size: 44px;
      line-height: 53px;
    }
  }
  .partners {
    flex-wrap: wrap;
    max-width: 600px;
    display: flex;
    justify-content: center;
    gap: 22px;
    @media (max-width: 880px) {
      max-width: 500px;
    }
    @media (max-width: 400px) {
      gap: 12px;
    }
    .partner {
      max-width: 183px;
      max-height: 97px;
      @media (max-width: 880px) {
        max-width: 120px;
        max-height: 63px;
      }
      img {
        width: 100%;
        height: 100%;
      }
      @media (max-width: 375px) {
        max-width: 100px;
        max-height: 54px;
      }
    }
  }
}
