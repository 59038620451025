.section {
  background: #0b0e28;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  @media (min-width: 1440px) {
    overflow: auto;
  }
  .wrapper {
    padding: 120px 0 90px 0;
    max-width: 1440px;
    width: 100%;
  }
  .title {
    font-family: 'Gilroy-Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 73px;
    color: #ffffff;
    width: 100%;
    text-align: center;
    @media (max-width: 550px) {
      font-size: 44px;
      line-height: 53px;
    }
  }
  .desc {
    font-family: 'Gilroy-Regular', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    width: 100%;
    text-align: center;
    color: #ffffff;
    max-width: 450px;
    margin: 20px auto 0;
    @media (max-width: 550px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  .blocks {
    margin-top: 50px;
    .block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      @media (max-width: 550px) {
        display: block;
      }
      &:nth-child(1),
      &:nth-child(3) {
        .firstCol {
          margin-left: 40px;
          order: 1;
        }
        .secondCol {
          order: 2;
        }
      }
      &:nth-child(2),
      &:nth-child(4) {
        .firstCol {
          order: 2;
          margin-right: 40px;
        }
        .secondCol {
          order: 1;
        }
      }
      .firstCol {
        display: flex;
        flex-direction: column;
        max-width: 380px;
        @media (max-width: 550px) {
          margin-left: 20px !important;
          margin-top: 50px !important;
        }
        .number {
          font-family: 'Gilroy-Bold', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 80px;
          line-height: 97px;
          color: rgba(234, 234, 245, 0.2);
          @media (max-width: 768px) {
            font-size: 60px;
            line-height: 73px;
          }
        }
        .titleBlock {
          margin-top: 7px;
          font-family: 'Gilroy-Bold', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 44px;
          color: #ffffff;
          @media (max-width: 1024px) {
            font-size: 30px;
            line-height: 36px;
          }
          @media (max-width: 768px) {
            font-size: 26px;
            line-height: 31px;
          }
        }
        .text {
          margin-top: 14px;
          font-family: 'Gilroy-Bold', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #c8c8c8;
          // max-width: 308px;
          @media (max-width: 1024px) {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
      .secondCol {
        width: 713px;
        height: 713px;
        position: relative;

        @media (max-width: 1024px) {
          width: 530px;
          height: 530px;
        }
        @media (max-width: 768px) {
          width: 351.86px;
          height: 351.86px;
        }
        @media (max-width: 550px) {
          margin-top: 25px;
        }
        .newCircle {
          width: 713px;
          height: 713px;
          background: transparent;
          border: 2px solid #ffc500;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 160px;
          z-index: 10;
          @media (max-width: 1024px) {
            width: 530px;
            height: 530px;
            left: 144px;
          }
          @media (max-width: 768px) {
            width: 351.86px;
            height: 351.86px;
            left: 130px;
          }
        }
        .circle {
          width: 713px;
          height: 713px;
          border-radius: 50%;
          position: relative;
          z-index: 10;

          @media (max-width: 1024px) {
            width: 530px;
            height: 530px;
          }
          @media (max-width: 768px) {
            width: 351.86px;
            height: 351.86px;
          }
          &.first,
          &.third {
            right: -100px;
          }
          &.second,
          &.four {
            left: -100px;
          }
          &.first {
            background: #4882fe;
          }
          &.second {
            background: rgba(255, 255, 255, 0.1);
          }
          &.third {
            background: #ffc500;
          }
          &.four {
            background: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(20px);
          }
        }
        img {
          position: absolute;
          top: 0;
          z-index: 11;
        }
        // .firstImg,
        // .secondImg {
        //   @media (max-width: 1024px) {
        //     width: 590px;
        //     height: 482px;
        //   }
        //   @media (max-width: 768px) {
        //     height: 200px;
        //     width: 300px;
        //   }
        // }

        // .thirdImg,
        // .fourImg {
        //   @media (max-width: 1024px) {
        //     width: 240.01px;
        //     height: 350.52px;
        //   }
        //   @media (max-width: 768px) {
        //     width: 165.01px;
        //     height: 270.52px;
        //   }
        // }
       
        .firstImg,
        .fourImg {
          @media (max-width: 1024px) {
            width: 550px;
            height: 452px;
            top: 30px;
          }
          @media (max-width: 768px) {
            width: 328px;
            height: 262px;
            top: 60px;
            // left: 40px;
          }
          @media (max-width: 550px) {
            width: 393px;
            height: 302px;
            /* top: 60px; */
            left: 31px;
          }
        }
        .firstImg {
          @media (max-width: 550px) {
           left: 0px;
          }
        }

        .secondImg {
          @media (max-width: 1024px) {
            width: 600px;
            height: 422px;
            top: 50px;
          }
          @media (max-width: 768px) {
            width: 400px;
            height: 292px;
            top: 30px;
          }
        }

        .thirdImg {
          @media (max-width: 1024px) {
            width: 560px;
            height: 562px;
          }
          @media (max-width: 768px) {
            width: 350px;
            height: 362px;
            left: 50px;
          }
          @media (max-width: 550px) {
            width: 370px;
            height: 372px;
            left: 60px;
          }
          
        }

        .fourImg {
          // @media (max-width: 1024px) {
          //   width: 550px;
          //   height: 452px;
          //   top: 30px;
          // }
          // @media (max-width: 768px) {
          //   top: 50px;
          //   right: 150px;
          // }
          @media (max-width: 425px) {
            left: 0;
          }
          @media (max-width: 350px) {
            left: -20px;
          }
        }
        .circleNew {
          width: 519px;
          height: 519px;
          border-radius: 50%;
          background: #4882fe;
          position: absolute;
          top: 87px;
          right: 70px;
          z-index: 5;
          @media (max-width: 1024px) {
            width: 396.71px;
            height: 396.71px;
          }
          @media (max-width: 768px) {
            width: 329.71px;
            height: 329.71px;
            top: 20px;
            // right: 0px;
          }
        }
      }
    }
  }
}
