.section {
  background: #4882fe;
  width: 100%;
  display: flex;
  justify-content: center;
  .wrapper {
    max-width: 1440px;
    width: 100%;
    padding: 120px 60px;
    box-sizing: border-box;
    @media (max-width: 1024px) {
      padding: 120px 40px;
    }
    @media (max-width: 768px) {
      padding: 80px 30px;
    }
    @media (max-width: 375px) {
      padding: 50px 20px;
    }
  }
  .contact {
    background: #ffffff;
    box-shadow: 0px 20px 60px rgba(8, 11, 24, 0.15);
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    padding: 60px;
    gap: 40px;
    @media (max-width: 1024px) {
      padding: 60px 40px;
    }
    @media (max-width: 768px) {
      padding: 60px 30px;
    }
    @media (max-width: 550px) {
      flex-direction: column;
    }
    .first {
      margin-top: 110px;
      @media (max-width: 550px) {
        margin: 0;
      }
      .title {
        font-family: 'Gilroy-Bold', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 75px;
        line-height: 91px;
        color: #0b0e28;
        white-space: nowrap;
        @media (max-width: 1024px) {
          font-size: 60px;
          line-height: 73px;
        }
        @media (max-width: 768px) {
          font-size: 34px;
          line-height: 41px;
        }
        @media (max-width: 550px) {
          font-size: 44px;
          line-height: 53px;
        }
      }
      .text {
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #0b0e28;
        margin-top: 20px;
        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 19px;
        }
        @media (max-width: 550px) {
          font-size: 20px;
          line-height: 24px;
        }
      }
      .info {
        margin-top: 36px;
        display: flex;
        align-items: center;
        gap: 40px;
        @media (max-width: 880px) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
        @media (max-width: 550px) {
          gap: 20px;
          margin-top: 30px;
        }
        .infoBlock {
          display: flex;
          align-items: center;
          gap: 15px;

          .icon {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #3675fe;
          }
          .desc {
            text-decoration: none;
            font-family: 'Gilroy', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #0b0e28;
            // @media (max-width: 768px) {
            //   font-size: 14px;
            //   line-height: 16px;
            // }
            &:nth-child(2) {
              cursor: pointer;
            }
          }
        }
      }
    }
    .second {
      width: 100%;
      max-width: 440px;
      position: relative;
      @media (max-width: 768px) {
        max-width: 315px;
      }

      .desc {
        margin-top: 30px;
        font-family: 'Gilroy-Regular', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;

        color: #797892;
        &:nth-child(1) {
          margin-top: 0;
        }
      }
      .input {
        margin-top: 6px;
        font-family: 'Gilroy-Regular', sans-serif;
        font-style: normal;
        font-weight: 600;
        width: 100%;
        height: 44px;
        background: #ffffff;
        border: 1px solid #d0d0e6;
        border-radius: 10px;
        color: #0b0e28;
        font-size: 14px;
        padding: 0;
        resize: none;
        text-indent: 10px;
     
      }
      .btnNew {
        margin-top: 30px;
        width: 100%;
      }
      .resp {
        position: absolute;
        bottom: -50px;
        font-family: 'Gilroy-Regular', sans-serif;
        font-style: normal;
        font-size: 16px;
        font-weight: 600;
        width: 100%;
        text-align: left;
        @media (max-width: 1000px) {
          font-size: 14px;
        }
      }
    }
  }
}
